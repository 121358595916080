
    import _Handlebars from 'handlebars/runtime';
    var Handlebars = _Handlebars.default;
    import "i18n!registration.teacher_dialog";
import "@canvas/handlebars-helpers/index.coffee";

    var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
    var name = '/home/canvas/public-html/ui/shared/signup-dialog/jst/teacherDialog';
    templates[name] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n    <div class=\"control-group\">\n      <div class=\"controls\">\n        <label class=\"checkbox\">\n          <input type=\"checkbox\" name=\"user[terms_of_use]\" value=\"1\">\n          ";
  if (helper = helpers.terms_html) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.terms_html); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </label>\n      </div>\n    </div>\n    ";
  return buffer;
  }

function program3(depth0,data) {
  
  
  return "\n    <div class=\"control-group\">\n      <div class=\"controls\">\n        <div class=\"g-recaptcha\"></div>\n      </div>\n    </div>\n    ";
  }

  buffer += "<div class=\"registration-dialog\">\n  <form class=\"form-horizontal bootstrap-form\" method=\"post\" action=\"/users\">\n    <div class=\"control-group\">\n      <label class=\"control-label\" for=\"teacher_name\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'scope': ("registration.teacher_dialog")
  },data:data},helper ? helper.call(depth0, "labels.name", "Name", options) : helperMissing.call(depth0, "t", "labels.name", "Name", options)))
    + "</label>\n      <div class=\"controls\">\n        <input type=\"text\" id=\"teacher_name\" name=\"user[name]\">\n      </div>\n    </div>\n    <div class=\"control-group\">\n      <label class=\"control-label\" for=\"teacher_email\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'scope': ("registration.teacher_dialog")
  },data:data},helper ? helper.call(depth0, "labels.email", "Email", options) : helperMissing.call(depth0, "t", "labels.email", "Email", options)))
    + "</label>\n      <div class=\"controls\">\n        <input type=\"text\" id=\"teacher_email\" name=\"pseudonym[unique_id]\">\n      </div>\n    </div>\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.terms_required), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.recaptcha), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n    <div class=\"control-group\">\n      <div class=\"controls\">\n        <input type=\"hidden\" name=\"user[initial_enrollment_type]\" value=\"teacher\">\n        <button class=\"btn btn-primary\" type=\"submit\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'scope': ("registration.teacher_dialog")
  },data:data},helper ? helper.call(depth0, "buttons.start_teaching", "Start Teaching", options) : helperMissing.call(depth0, "t", "buttons.start_teaching", "Start Teaching", options)))
    + "</button>\n      </div>\n    </div>\n  </form>\n</div>\n";
  return buffer;
  });
    ;
    ;
    export default templates[name];
  